import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackToWork from "../components/backtowork"

const InternalPage = () => (
  <Layout>
    <SEO title="My Sofa Custom Sofa Builder" />
    <main className="internal">
      <BackToWork />
      <div className="content">
        <div className="full">
          <h1>Prototype: My Sofa Custom Sofa Builder</h1>
          <p>To provide customers a visual and unique method to customize their own piece of furniture, Art Van Outlet wanted customers to be able to do build their own sofa online. From the sofa style to the pillows, in this experience customers could be able to design their own unique sofa.</p>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649034/hireallisonokeefe/my-sofa-custom-sofa-builder/mysofa_01_cn9rol.jpg" alt="" />
            <figcaption>Above: initial pencil sketches for My Sofa mobile.</figcaption>
          </figure>
        </div>
        <div className="half">
          <figure>
            <img src="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584649034/hireallisonokeefe/my-sofa-custom-sofa-builder/mysofa_02_vc0fwu.png" alt="" />
            <figcaption>Above: snippet of work-in-progress mobile artboard.</figcaption>
          </figure>
        </div>
        <div className="full">
          <p>Upwards of 68% of the Art Van online traffic is mobile; it was necessary for mobile to be considered first before working backwards to desktop (around 26% of online traffic).</p>
          <p>As a customer might experience in the stores, the purpose of either the mobile or desktop sofa builders was to engage the customer step by step through building their own unique sofas:</p>
          <ol>
            <li>Choose Your Frame &ndash; Gives customers 3 distinct styles of My Sofa to choose from</li>
            <li>Choose Your Color &ndash; 3 basic colors for the customer to match their current decor</li>
            <li>Choose Your Pillow &ndash; Style the sofa with a unique pattern of pillow (up to 12 different patterns)</li>
          </ol>
          <p>Throughout this interactive sofa building experience, it was important for the customer to easily be able to go back and make any type of change at their will. This was dictated by clear editing functionality where any choice could be changed after the fact and saved.</p>
          <p>It was also important to include a clear call-to-action at the end of this experience &ndash; a prominent "Shop This Sofa" button within the customer's overview to take the customer to the product detail page and create a conversion.</p>
          <div className="video-wrapper">
            <iframe title="Prototype of custom sofa builder experience" src="https://player.cloudinary.com/embed/?cloud_name=innocent&public_id=hireallisonokeefe%2Fmy-sofa-custom-sofa-builder%2Fmysofa-video_lflecu&fluid=true&controls=true&source_types%5B0%5D=mp4" allow="fullscreen; encrypted-media; picture-in-picture" allowFullScreen frameBorder="0" />
          </div>
          <p><span className="text--bold">Programs used:</span> Sketch App</p>
        </div>
      </div>
    </main>
  </Layout>
)

export default InternalPage
